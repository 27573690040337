import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-charlotte-north-carolina.png'
import image0 from "../../images/cities/scale-model-of-charlotte-segway-tours-in-charlotte-north-carolina.png"
import image1 from "../../images/cities/scale-model-of-musical-parking-garage-in-charlotte-north-carolina.png"
import image2 from "../../images/cities/scale-model-of-il-grande-disco-in-charlotte-north-carolina.png"
import image3 from "../../images/cities/scale-model-of-carolinas-aviation-museum-in-charlotte-north-carolina.png"
import image4 from "../../images/cities/scale-model-of-nada's-italy-in-charlotte-north-carolina.png"
import image5 from "../../images/cities/scale-model-of-levine-museum-of-the-new-south-in-charlotte-north-carolina.png"
import image6 from "../../images/cities/scale-model-of-mint-museum-uptown-in-charlotte-north-carolina.png"
import image7 from "../../images/cities/scale-model-of-bechtler-museum-of-modern-art-in-charlotte-north-carolina.png"
import image8 from "../../images/cities/scale-model-of-harvey-b.-gantt-center-for-african-american-arts-+-culture-in-charlotte-north-carolina.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Charlotte'
            state='North Carolina'
            image={image}
            lat='35.2078'
            lon='-80.831'
            attractions={ [{"name": "Charlotte Segway Tours", "vicinity": "101 S Tryon St suite 16, Charlotte", "types": ["travel_agency", "store", "point_of_interest", "establishment"], "lat": 35.226485, "lng": -80.84270900000001}, {"name": "Musical Parking Garage", "vicinity": "231 E 7th St, Charlotte", "types": ["point_of_interest", "establishment"], "lat": 35.22754460000001, "lng": -80.83848720000003}, {"name": "Il Grande Disco", "vicinity": "E with N St, S Trade St, Tryon", "types": ["point_of_interest", "establishment"], "lat": 35.2268976, "lng": -80.84316739999997}, {"name": "Carolinas Aviation Museum", "vicinity": "4672 1st Flight Dr, Charlotte", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.2194275, "lng": -80.93021520000002}, {"name": "Nada's Italy", "vicinity": "310 East Blvd Suite 4, Charlotte", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 35.2103449, "lng": -80.85632399999997}, {"name": "Levine Museum of the New South", "vicinity": "200 E 7th St, Charlotte", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.2281616, "lng": -80.83839169999999}, {"name": "Mint Museum UPTOWN", "vicinity": "500 S Tryon St, Charlotte", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.224305, "lng": -80.84815100000003}, {"name": "Bechtler Museum of Modern Art", "vicinity": "420 S Tryon St, Charlotte", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.2244822, "lng": -80.84732789999998}, {"name": "Harvey B. Gantt Center for African-American Arts + Culture", "vicinity": "551 S Tryon St, Charlotte", "types": ["museum", "art_gallery", "point_of_interest", "establishment"], "lat": 35.2233733, "lng": -80.84822020000001}] }
            attractionImages={ {"Charlotte Segway Tours":image0,"Musical Parking Garage":image1,"Il Grande Disco":image2,"Carolinas Aviation Museum":image3,"Nada's Italy":image4,"Levine Museum of the New South":image5,"Mint Museum UPTOWN":image6,"Bechtler Museum of Modern Art":image7,"Harvey B. Gantt Center for African-American Arts + Culture":image8,} }
       />);
  }
}